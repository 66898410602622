<template>
    <div class="CylinderInfo_footer">{{ listen ? "ON" : "OFF" }} {{ code }}</div>
  </template>
  <script>
  export default {
    name: "KeyboardScenner",
    props: {
      listen: Boolean,
    },
    data: function () {
      return {
        code: "",
        listener: null,
        timeout: null,
      };
    },
    computed: {},
    watch: {
      listen: function () {
        if (this.listen) {
          document.addEventListener("keypress", this.logKey);
        } else {
          document.removeEventListener("keypress", this.logKey);
        }
      },
    },
    methods: {
      logKey: function (e) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(
          function () {
            this.code = "";
          }.bind(this),
          2000
        );
      //   console.log(e.key);
        if (/^[A-Z0-9]+$/.test(e.key)) {
          this.code += e.key;
        }
        if (this.code.length == 6) {
          this.$emit("input", this.code);
          this.code = "";
        }
      },
    },
    mounted() {
      if (this.listen) {
        document.removeEventListener("keypress", this.logKey);
        document.addEventListener("keypress", this.logKey);
      } else {
        document.removeEventListener("keypress", this.logKey);
      }
    },
    beforeDestroy: function () {
      clearTimeout(this.timeout);
      document.removeEventListener("keypress", this.logKey);
    },
  };
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .CylinderInfo_footer {
    position: fixed;
    right: 0px;
    bottom: 0px;
    font-size: 10px;
  }
  </style>
  