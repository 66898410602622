import api from "../api/myApi";
export default {
  namespaced: true,
  state: {
    orders: {},
    products: {},
    categories: [],
    clientStorages: [],
    productDictionary: [],
    orderApproveQuene: [],
    orderSending: false,
    cylinderDb: [],
  },
  mutations: {
    setProductDictionary: function (state, data) {
      state.productDictionary = data;
    },
    setCategories: function (state, data) {
        state.categories = data;
      },
    setCylinders: function (state, data) {
      state.cylinders = data;
    },
    clearOrderQuene: function (state) {
      state.orderApproveQuene = [];
    },
    setOrders: function (state, data) {
      state.orders = data;
    },
    setProducts: function (state, data) {
      state.products = data;
    },
    addClientStorage: function (state, data) {
      state.clientStorages.push(data);
    },
    clearClientStorage: function (state) {
      state.clientStorages = [];
    },
    setCylinderDb: function (state, data) {
      state.cylinderDb = data;
    },
    addOrderToApprove: function (state, data) {
      data._synced = false;
      state.orderApproveQuene.push(data);
    },
    removeSyncedOrders: function (state) {
      let tab = state.orderApproveQuene.filter((item) => item._synced == false);
      if (tab.length == 0) {
        state.orderSending = false;
      }
      state.orderApproveQuene = tab;
    },
  },
  getters: {
    getProducts: (state) => {
      return state.products;
    },
    getProductFromDictionary: (state) => (id) => {
      return state.productDictionary.find((item) => item.product_id == id);
    },
    getOrders: (state) => {
      return state.orders;
    },
    getProduct: (state) => (id) => {
      return state.products.find((item) => item.product_id == id);
    },
    getOrder: (state) => (id) => {
      return state.orders.find((item) => item.id == id);
    },
    getOpenOrders: (state) => {
      return state.orders.filter((item) => ["3"].includes(item.status));
    },
    getPickupOrders: (state) => {
      return state.orders.filter((item) => ["2", "3"].includes(item.status));
    },
    getCylinderQrcode: (state) => (id) => {
      let cylinder = null;
      state.products.forEach((item) => {
        let it = item.cylinders.find((it) => it.cylinder_qr_code == id);
        if (it) cylinder = it;
      });
      return cylinder;
    },
    getCylinderDbQrcode: (state) => (code) => {
      let cylinder = state.cylinderDb.find((item) => item.cylinder_qr_code == code);
      if (cylinder) {
        let product = state.productDictionary.find(
          (item) => item.product_id == cylinder.product_id
        );
        cylinder.cylinder_name = product ? product.name : "[NIE ZNALEZIONO NAZWY]";
        cylinder._offline = true;
      }
      return cylinder;
    },
    isInClient: (state) => (location_id, id) => {
      let client = state.clientStorages.find((item) => (item.location_id = location_id));
      if (client) {
        return client.products.find((item) => item.product_id == id) !== undefined;
      }
      return false;
    },
  },
  actions: {
    syncState({ commit, rootState, dispatch, state }) {
      return new Promise((resolve, reject) => {
        const promise1 = api.findLocationStorageState(rootState.location_id);
        const promise2 = api.getOrders(rootState.location_id);
        Promise.all([promise1, promise2])
          .then((responses) => {
            console.log(responses);
            state.orderSending = false;
            commit("setProducts", responses[0].data.data);
            commit("setOrders", responses[1].data.data);
            dispatch("syncClientStorages");
            dispatch("syncProductDictionary");
            dispatch("syncCylinderDb");
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    syncClientStorages({ state, commit }) {
      let promises = [];
      commit("clearClientStorage");
      state.orders.forEach((item) => {
        promises.push(api.findLocationStorageState(item.destination.location_id));
      });
      Promise.all(promises).then((responses) => {
        responses.forEach((response) => {
          commit("addClientStorage", {
            products: response.data.data,
            location_id: response.data.location_id,
          });
        });
      });
    },
    syncCylinderDb({ commit }) {
      api.getCylinderDb().then((response) => {
        commit("setCylinderDb", response.data.data);
      });
    },
    syncOrderApprove({ state, commit, dispatch }, vm) {
      commit("removeSyncedOrders");
      if (state.orderSending) return;
      state.orderApproveQuene.forEach((item) => {
        state.orderSending = true;
        api.closeOrder(item).then(
          () => {
            item._synced = true;
            commit("removeSyncedOrders");
            dispatch("syncState");
            vm.$toast("Zsychronizowano zamówienie z serwerm pomyślnie");
          },
          () => {
            state.orderSending = false;
            vm.$swal("Błąd w synchronizacji kolejki");
          }
        );
      });
    },
    syncProductDictionary({ commit }) {
      api.getProductsDictionary().then((response) => {
        commit("setProductDictionary", response.data.data);
        commit("setCategories", response.data.categories);
      });
    },
    findCylinder({ getters }, qrcode) {
      return new Promise((resolve, reject) => {
        let cylinder = getters.getCylinderQrcode(qrcode);
        if (cylinder) {
          resolve(cylinder);
        } else {
          api.findCylinder(qrcode).then(
            (response) => {
              resolve(response.data);
            },
            () => {
              reject();
            }
          );
        }
      });
    },
  },
};
