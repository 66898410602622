<template>
    <div>
      <div
        class="rounded-3 p-2 border mt-2"
        v-for="(item, key) in logs"
        :key="key"
      >
        <span v-if="item.log_name == 'location'"
          >Zamiana lokalizacji na
          {{ item.location ? item.location.location_name : item.log_value }}
          <span class="d-block small opacity-50"> {{ item.log_created_at }}</span>
        </span>
        <span v-if="item.log_name == 'fillup'"
          >Butla została napełniona.
          <span class="d-block small opacity-50"> {{ item.log_created_at }}</span>
        </span>
        <span v-if="item.log_name == 'product'"
          >Zmiana towaru w butli.
          <span class="d-block small opacity-50"> {{ item.log_created_at }}</span>
        </span>
        <span v-if="item.log_name == 'empty'"
          >Butla została opróżniona.
          <span class="d-block small opacity-50"> {{ item.log_created_at }}</span>
        </span>
        <span v-if="item.log_name == 'complaint'"
          >Butla została reklamowana.
          <span class="d-block small opacity-50"> {{ item.log_created_at }}</span> </span
        ><span v-if="item.log_name == 'service_open'"
          >Otwarto zgłoszenie serwisowe. Butla przyjęta.
          <span class="d-block small opacity-50"> {{ item.log_created_at }}</span>
        </span>
        <span v-if="item.log_name == 'service_ready'"
          >Rozwiązano zgłoszenie serwisowe. Butla w drodze do miejsca odbioru
          <span class="d-block small opacity-50"> {{ item.log_created_at }}</span>
        </span>
        <span v-if="item.log_name == 'service_done'"
          >Zakończono zgłoszenie serwisowe. Butla została wydana do klienta
          <span class="d-block small opacity-50"> {{ item.log_created_at }}</span>
        </span>
      </div>
    </div>
  </template>
  <script>
  import myApi from "@/api/myApi";
  
  export default {
    name: "CylinderLogs",
    props: ["cylinder_id", "limit"],
    data: function () {
      return {
        statusLabel: { full: "Pełna", emtpy: "Pusta" },
        logs: [],
      };
    },
    watch: {
      cylinder_id: function () {
        this.getLogs();
      },
    },
    methods: {
      getLogs: function () {
        if (!this.cylinder_id) {
          return;
        }
        let vm = this;
        myApi.getCylinderLogs(this.cylinder_id, 5).then((response) => {
          vm.logs = response.data.data;
        });
      },
    },
    mounted() {
      this.getLogs();
    },
  };
  </script>
  