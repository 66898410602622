<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/loading"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Wybierz produkt</h2>
    </div>
    <div
      class="w-100 p-3 pb-5 flex-grow-1"
      style="overflow: auto"
    >
      <input
        type="text"
        v-model="filter"
        class="form-control mb-2"
        placeholder="Wyszukaj"
      />
      <div v-if="categorySelected == ''">
        <div
          v-for="(item, key) in categories"
          :key="key"
        >
          <a class="bg-light p-2 text-dark d-flex justify-content-between mb-2">
            <div class="col-9 pe-2 d-flex justify-content-between">
              {{ item.name }}
            </div>
            <span class="col-3"
              ><button
                @click.prevent="categorySelected = item.key"
                class="btn btn-primary w-100"
              >
                Wybierz
              </button></span
            >
          </a>
        </div>
      </div>
      <div v-else>
        <a
          @click.prevent="categorySelected = ''"
           class="btn btn-outline-secondary mb-2"
          href="#"
          >Wróć do kategorii</a
        >
        <div
          v-for="(item, key) in productsFiltered"
          :key="key"
        >
          <a
            @click="addProduct(item)"
            class="bg-light p-2 text-dark d-flex justify-content-between mb-2"
          >
            <div class="col-9 pe-2 d-flex justify-content-between">
              {{ item.name }}
              <div v-if="item.available">
                <span class="badge bg-success">U klienta</span>
              </div>
            </div>
            <span class="col-3"><button class="btn btn-primary w-100">Wybierz</button></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DisloadingSelectOrder",
  components: {},
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      categorySelected: "",
      filter: "",
    };
  },
  props: {},
  computed: {
    categories: function () {
      return this.$store.state.car.categories;
    },
    productsFiltered: function () {
      if ((this.categorySelected == "")) {
        return [];
      }
      return this.products.filter((item) => {
        return (
          (item.name.toLowerCase().includes(this.filter.toLowerCase()) || this.filter == "") &&
          item.cat == this.categorySelected
        );
      });
    },
  },
  methods: {
    addProduct: function (item) {
      this.$store.commit("zaladunek/addProduct", item);
      this.$router.push("/loading");
    },
  },
  created() {
    this.products = JSON.parse(JSON.stringify(this.$store.state.car.productDictionary));
    let location_id = this.$store.state.pickup.order.destination.location_id;
    this.products.forEach((item) => {
      item.available = this.$store.getters["car/isInClient"](location_id, item.product_id);
    });
    this.products.sort((a, b) => {
      return a.available < b.available ? 1 : -1;
    });
  },
  mounted() {},
};
</script>
